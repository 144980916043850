import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slideTemplate", "slide"]
  static values = {
    index: { type: Number, default: 0 },
    speed: { type: Number, default: 10000 }
  }

  initialize() {
    this.shuffledSlideTemplates = shuffleArray(this.slideTemplateTargets)
  }

  connect() {
    this.loading = false
    this.start()
  }

  disconnect() {
    this.stop()
  }

  start() {
    this.interval = setInterval(() => {
      this.next()
    }, this.speedValue)
  }

  stop() {
    clearInterval(this.interval)
  }

  next() {
    if (this.indexValue === this.slideTemplateTargets.length - 1) {
      this.indexValue = 0
    } else {
      this.indexValue++
    }
  }

  resetTimer() {
    this.stop()
    this.start()
  }

  indexValueChanged() {
    this.#loadNext()
  }

  onLazyloaded(event) {
    if (!this.loading) return
    if (this.#currentSlide) this.#currentSlide.remove()
    this.loading = false
  }

  #loadNext() {
    this.loading = true
    const template = this.shuffledSlideTemplates[this.indexValue]
    this.element.appendChild(template.content.cloneNode(true))
  }

  get #currentSlide() {
    return this.hasSlideTarget ? this.slideTarget : null
  }
}

function shuffleArray(array) {
  let res = array
  for (var i = res.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = res[i]
    res[i] = res[j]
    res[j] = temp
  }
  return res
}
