import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static values = {
    fps: { default: 30, type: Number },
    x: { default: 0, type: Number },
    y: { default: 0, type: Number },
    directionX: { default: 0.5, type: Number },
    directionY: { default: 0.5, type: Number }
  }

  static debounces = ["contain", "calculateContainer"]

  connect() {
    this.calculateContainer()
    useDebounce(this)
    this.directionXValue = (Math.random() - 0.5) * 2
    this.directionYValue = (Math.random() - 0.5) * 2
    this.start()
  }

  disconnect() {
    this.stop()
  }

  contain() {
    if (
      this.hasReachedLeft ||
      this.hasReachedRight ||
      this.hasReachedTop ||
      this.hasReachedBottom
    ) {
      this.stop()
      this.xValue = 0
      this.yValue = 0
      this.start()
    }
  }

  start() {
    this.interval = setInterval(() => {
      this.directionXValue = this.directionX
      this.directionYValue = this.directionY
      this.xValue = this.xValue + this.directionXValue
      this.yValue = this.yValue + this.directionYValue
    }, 1000 / this.fpsValue)
  }

  stop() {
    if (this.interval) clearInterval(this.interval)
  }

  xValueChanged() {
    this.element.style.setProperty("--x", `${this.xValue}px`)
  }

  yValueChanged() {
    this.element.style.setProperty("--y", `${this.yValue}px`)
  }

  calculateContainer() {
    this.containerBoundingClientRect = this.container.getBoundingClientRect()
  }

  get randomDirection() {
    return -0.5 + Math.random() * (max - min + 1)
  }

  get directionX() {
    return this.hasReachedLeft || this.hasReachedRight
      ? this.directionXValue * -1
      : this.directionXValue
  }

  get directionY() {
    return this.hasReachedTop || this.hasReachedBottom
      ? this.directionYValue * -1
      : this.directionYValue
  }

  get hasReachedBottom() {
    return (
      this.boundingClientRect.bottom >= this.containerBoundingClientRect.bottom
    )
  }

  get hasReachedTop() {
    return this.boundingClientRect.top <= 0
  }

  get hasReachedLeft() {
    return this.boundingClientRect.left <= 0
  }

  get hasReachedRight() {
    return (
      this.boundingClientRect.right >= this.containerBoundingClientRect.right
    )
  }

  get boundingClientRect() {
    return this.element.getBoundingClientRect()
  }

  // get containerBoundingClientRect() {
  //   return this.container.getBoundingClientRect()
  // }

  get container() {
    return this.element.parentElement
  }
}
