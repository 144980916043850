
        import * as module0 from '$components/calendar.css';import * as module1 from '$components/event.css';import * as module2 from '$components/events/date_list.css';import * as module3 from '$components/layout/stack.css';import * as module4 from '$components/layout/text.css';import * as module5 from '$components/page.css';import * as module6 from '$components/project.css';import * as module7 from '$components/shared/date.css';import * as module8 from '$components/shared/date_range.css';import * as module9 from '$components/shared/dots.css';import * as module10 from '$components/shared/dots/dot.css';import * as module11 from '$components/shared/frame.css';import * as module12 from '$components/shared/icon.css';import * as module13 from '$components/shared/image.css';import * as module14 from '$components/shared/layout.css';import * as module15 from '$components/shared/navigation.css';import * as module16 from '$components/shared/newsletter_subscription.css';import * as module17 from '$components/shared/overlay.css';import * as module18 from '$components/shared/overlay_controller.js';import * as module19 from '$components/shared/slideshow.css';import * as module20 from '$components/shared/slideshow/slide.css'
        const modules = {
            "calendar.css": module0,
            "event.css": module1,
            "events/date_list.css": module2,
            "layout/stack.css": module3,
            "layout/text.css": module4,
            "page.css": module5,
            "project.css": module6,
            "shared/date.css": module7,
            "shared/date_range.css": module8,
            "shared/dots.css": module9,
            "shared/dots/dot.css": module10,
            "shared/frame.css": module11,
            "shared/icon.css": module12,
            "shared/image.css": module13,
            "shared/layout.css": module14,
            "shared/navigation.css": module15,
            "shared/newsletter_subscription.css": module16,
            "shared/overlay.css": module17,
            "shared/overlay_controller.js": module18,
            "shared/slideshow.css": module19,
            "shared/slideshow/slide.css": module20,
        };
        export default modules;
      