import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.body.classList.add("overlay")
  }

  disconnect() {
    document.body.classList.remove("overlay")
  }
}
